import { useTranslation } from '@echodex/localization'
import { Currency, CurrencyAmount, Token } from '@echodex/sdk'
import { Column, QuestionHelper, Text } from '@echodex/uikit'
import { formatAmount } from '@echodex/utils/formatFractions'
import { LightGreyCard } from 'components/Card'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { CSSProperties, MutableRefObject, useCallback, useMemo } from 'react'
import { FixedSizeList } from 'react-window'
import styled from 'styled-components'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { useAccount } from 'wagmi'
import { CurrencyLogo } from 'components/Logo'
import { useIsUserAddedToken } from '../../hooks/Tokens'
import { useCombinedActiveList } from '../../state/lists/hooks'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import { isTokenOnList } from '../../utils'
import { RowBetween, RowFixed } from '../Layout/Row'
import CircleLoader from '../Loader/CircleLoader'
import ImportRow from './ImportRow'


function currencyKey(currency: Currency): string {
    return currency?.isToken ? currency.address : currency?.isNative ? currency.symbol : ''
}

const StyledBalanceText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    max-width: 5rem;
    text-overflow: ellipsis;
`

const FixedContentRow = styled.div`
    padding: 4px 20px;
    height: 56px;
    display: grid;
    grid-gap: 16px;
    align-items: center;
`

function Balance({ balance }: { balance: CurrencyAmount<Currency> }) {
    return <StyledBalanceText title={balance.toExact()}>{formatAmount(balance, 4)}</StyledBalanceText>
}

const MenuItem = styled(RowBetween)<{ disabled: boolean; selected: boolean }>`
    padding: 4px 20px;
    height: 56px;
    display: grid;
    grid-template-columns: auto minmax(auto, 1fr) minmax(0, 72px);
    grid-gap: 8px;
    cursor: ${({ disabled }) => !disabled && 'pointer'};
    pointer-events: ${({ disabled }) => disabled && 'none'};
    :hover {
        background-color: ${({ theme, disabled }) => !disabled && theme.colors.background};
    }
    opacity: ${({ disabled, selected }) => (disabled || selected ? 0.5 : 1)};
`

function CurrencyRow({
    currency,
    onSelect,
    isSelected,
    otherSelected,
    style,
}: {
    currency: Currency
    onSelect: () => void
    isSelected: boolean
    otherSelected: boolean
    style: CSSProperties
    mode?: string
}) {
    const { address: account } = useAccount()
    const { t } = useTranslation()
    const key = currencyKey(currency)
    const selectedTokenList = useCombinedActiveList()
    const isOnSelectedList = isTokenOnList(selectedTokenList, currency)
    const customAdded = useIsUserAddedToken(currency)

    const balance = useCurrencyBalance(account ?? undefined, currency)

    // only show add or remove buttons if not on selected list
    return (
        <MenuItem
            style={style}
            className={`token-item-${key}`}
            onClick={() => (isSelected ? null : onSelect())}
            disabled={isSelected}
            selected={otherSelected}
        >
            <CurrencyLogo currency={currency} size="24px" />
            <Column>
                <Text bold>{currency?.symbol}</Text>
                <Text color="textSubtle" small ellipsis maxWidth="200px">
                    {!isOnSelectedList && customAdded && `${t('Added by user')} •`} {currency?.name}
                </Text>
            </Column>
            <RowFixed style={{ justifySelf: 'flex-end' }}>
                {balance ? <Balance balance={balance} /> : account && <CircleLoader />}
            </RowFixed>
        </MenuItem>
    )
}

export default function CurrencyList({
    height,
    currencies,
    inactiveCurrencies,
    selectedCurrency,
    onCurrencySelect,
    otherCurrency,
    fixedListRef,
    showNative,
    showImportView,
    setImportToken,
    breakIndex,
    mode,
}: {
    height: number | string
    currencies: Currency[]
    inactiveCurrencies: Currency[]
    selectedCurrency?: Currency | null
    onCurrencySelect: (currency: Currency) => void
    otherCurrency?: Currency | null
    fixedListRef?: MutableRefObject<FixedSizeList | undefined>
    showNative: boolean
    showImportView: () => void
    setImportToken: (token: Token) => void
    breakIndex: number | undefined
    mode: string
}) {
    const native = useNativeCurrency()

    const itemData: (Currency | undefined)[] = useMemo(() => {
        let formatted: (Currency | undefined)[] = showNative
            ? [native, ...currencies, ...inactiveCurrencies]
            : [...currencies, ...inactiveCurrencies]
        if (breakIndex !== undefined) {
            formatted = [...formatted.slice(0, breakIndex), undefined, ...formatted.slice(breakIndex, formatted.length)]
        }
        return formatted
    }, [breakIndex, currencies, inactiveCurrencies, showNative, native])

    const { chainId } = useActiveChainId()

    const { t } = useTranslation()

    const Row = useCallback(
        ({ data, index, style }) => {
            const currency: any = data[index]

            // the alternative to making a fiat currency token list
            // with class methods
            let isSelected = false
            let otherSelected = false
            isSelected = Boolean(selectedCurrency && currency && selectedCurrency.equals(currency))
            otherSelected = Boolean(otherCurrency && currency && otherCurrency.equals(currency))
            const handleSelect = () => onCurrencySelect(currency)

            const token = wrappedCurrency(currency, chainId)

            const showImport = index > currencies.length

            if (index === breakIndex || !data) {
                return (
                    <FixedContentRow style={style}>
                        <LightGreyCard padding="8px 12px" borderRadius="8px">
                            <RowBetween>
                                <Text small>{t('Expanded results from inactive Token Lists')}</Text>
                                <QuestionHelper
                                    text={t(
                                        "Tokens from inactive lists. Import specific tokens below or click 'Manage' to activate more lists.",
                                    )}
                                    ml="4px"
                                />
                            </RowBetween>
                        </LightGreyCard>
                    </FixedContentRow>
                )
            }

            if (showImport && token) {
                return (
                    <ImportRow
                        onCurrencySelect={handleSelect}
                        style={style}
                        token={token}
                        showImportView={showImportView}
                        setImportToken={setImportToken}
                        dim
                    />
                )
            }
            return (
                <CurrencyRow
                    style={style}
                    currency={currency}
                    isSelected={isSelected}
                    onSelect={handleSelect}
                    otherSelected={otherSelected}
                    mode={mode}
                />
            )
        },
        [
            selectedCurrency,
            otherCurrency,
            chainId,
            currencies.length,
            breakIndex,
            onCurrencySelect,
            t,
            showImportView,
            setImportToken,
            mode,
        ],
    )

    const itemKey = useCallback((index: number, data: any) => `${currencyKey(data[index])}-${index}`, [])

    return (
        <FixedSizeList
            height={height}
            ref={fixedListRef as any}
            width="100%"
            itemData={itemData}
            itemCount={itemData.length}
            itemSize={56}
            itemKey={itemKey}
        >
            {Row}
        </FixedSizeList>
    )
}
