import { ChainId, Currency, Token } from '@echodex/sdk'
import { Text, QuestionHelper, AutoColumn } from '@echodex/uikit'
import styled from 'styled-components'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { useTranslation } from '@echodex/localization'
import { CurrencyLogo } from 'components/Logo'
import { SUGGESTED_BASES } from 'config/constants/exchange'
import { AutoRow } from '../Layout/Row'
import { CommonBasesType } from './types'

const ButtonWrapper = styled.div`
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
`

const BaseWrapper = styled.div<{ disable?: boolean }>`
    border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.colors.dropdown)};
    border-radius: 10px;
    display: flex;
    padding: 6px;
    align-items: center;
    :hover {
        cursor: ${({ disable }) => !disable && 'pointer'};
        background-color: ${({ theme, disable }) => !disable && theme.colors.background};
    }
    background-color: ${({ theme, disable }) => disable && theme.colors.dropdown};
    opacity: ${({ disable }) => disable && '0.4'};
`

export default function CommonBases({
    chainId,
    onSelect,
    selectedCurrency,
    commonBasesType,
}: {
    chainId?: ChainId
    commonBasesType
    selectedCurrency?: Currency | null
    onSelect: (currency: Currency) => void
}) {
    const native = useNativeCurrency()
    const { t } = useTranslation()
    const pinTokenDescText =
        commonBasesType === CommonBasesType.SWAP_LIMITORDER ? t('Common tokens') : t('Common bases')

    return (
        <AutoColumn gap="md">
            <AutoRow>
                <Text fontSize="14px">{pinTokenDescText}</Text>
                {commonBasesType === CommonBasesType.LIQUIDITY && (
                    <QuestionHelper text={t('These tokens are commonly paired with other tokens.')} ml="4px" />
                )}
            </AutoRow>
            <AutoRow>
                <ButtonWrapper>
                    <BaseWrapper
                        onClick={() => {
                            if (!selectedCurrency || !selectedCurrency.isNative) {
                                onSelect(native)
                            }
                        }}
                        disable={selectedCurrency?.isNative}
                    >
                        <CurrencyLogo currency={native} style={{ marginRight: 8 }} />
                        <Text>{native?.symbol}</Text>
                    </BaseWrapper>
                </ButtonWrapper>
                {(chainId ? SUGGESTED_BASES[chainId] || [] : []).map((token: Token) => {
                    const selected = selectedCurrency?.equals(token)
                    return (
                        <ButtonWrapper key={`buttonBase#${token.address}`}>
                            <BaseWrapper onClick={() => !selected && onSelect(token)} disable={selected}>
                                <CurrencyLogo currency={token} style={{ marginRight: 8, borderRadius: '50%' }} />
                                <Text>{token.symbol}</Text>
                            </BaseWrapper>
                        </ButtonWrapper>
                    )
                })}
            </AutoRow>
        </AutoColumn>
    )
}
