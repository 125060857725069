/* eslint-disable no-restricted-syntax */
import { Currency, Token } from '@echodex/sdk'
import { Box, Input, Text, useMatchBreakpoints, AutoColumn, Column } from '@echodex/uikit'
import { KeyboardEvent, RefObject, useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { useTranslation } from '@echodex/localization'
import { useDebounce, useSortedTokensByQuery } from '@echodex/hooks'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { FixedSizeList } from 'react-window'
import { useAllLists, useInactiveListUrls } from 'state/lists/hooks'
import { WrappedTokenInfo, createFilterToken } from '@echodex/token-lists'
import { isAddress } from 'utils'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useAllTokens, useIsUserAddedToken, useToken } from '../../hooks/Tokens'
import Row from '../Layout/Row'
import CommonBases from './CommonBases'
import CurrencyList from './CurrencyList'
import useTokenComparator from './sorting'

import ImportRow from './ImportRow'

interface CurrencySearchProps {
    selectedCurrency?: Currency | null
    onCurrencySelect: (currency: Currency) => void
    otherSelectedCurrency?: Currency | null
    showSearchInput?: boolean
    showCommonBases?: boolean
    commonBasesType?: string
    showImportView: () => void
    setImportToken: (token: Token) => void
    height?: number
    tokensToShow?: Token[]
    mode?: string
    onRampFlow?: boolean
}

function useSearchInactiveTokenLists(search: string | undefined, minResults = 10): WrappedTokenInfo[] {
    const lists = useAllLists()
    const inactiveUrls = useInactiveListUrls()
    const { chainId } = useActiveChainId()
    const activeTokens = useAllTokens()
    return useMemo(() => {
        if (!search || search.trim().length === 0) return []
        const filterToken = createFilterToken(search, (address) => Boolean(isAddress(address)))
        const exactMatches: WrappedTokenInfo[] = []
        const rest: WrappedTokenInfo[] = []
        const addressSet: { [address: string]: true } = {}
        const trimmedSearchQuery = search.toLowerCase().trim()
        for (const url of inactiveUrls) {
            const list = lists[url]?.current
            // eslint-disable-next-line no-continue
            if (!list) continue
            for (const tokenInfo of list.tokens) {
                if (
                    tokenInfo.chainId === chainId &&
                    !(tokenInfo.address in activeTokens) &&
                    !addressSet[tokenInfo.address] &&
                    filterToken(tokenInfo)
                ) {
                    const wrapped: WrappedTokenInfo = new WrappedTokenInfo({
                        ...tokenInfo,
                        address: isAddress(tokenInfo.address) || tokenInfo.address,
                    })
                    addressSet[wrapped.address] = true
                    if (
                        tokenInfo.name?.toLowerCase() === trimmedSearchQuery ||
                        tokenInfo.symbol?.toLowerCase() === trimmedSearchQuery
                    ) {
                        exactMatches.push(wrapped)
                    } else {
                        rest.push(wrapped)
                    }
                }
            }
        }
        return [...exactMatches, ...rest].slice(0, minResults)
    }, [activeTokens, chainId, inactiveUrls, lists, minResults, search])
}

function CurrencySearch({
    selectedCurrency,
    onCurrencySelect,
    otherSelectedCurrency,
    showCommonBases,
    commonBasesType,
    showSearchInput = true,
    showImportView,
    setImportToken,
    height,
    tokensToShow,
    mode,
    onRampFlow,
}: CurrencySearchProps) {
    const { t } = useTranslation()
    const { chainId } = useActiveChainId()

    // refs for fixed size lists
    const fixedList = useRef<FixedSizeList>()

    const [searchQuery, setSearchQuery] = useState<string>('')
    const debouncedQuery = useDebounce(searchQuery, 200)

    const [invertSearchOrder] = useState<boolean>(false)

    const allTokens = useAllTokens()
    // if they input an address, use it
    const searchToken = useToken(debouncedQuery)
    const searchTokenIsAdded = useIsUserAddedToken(searchToken)

    const { isMobile } = useMatchBreakpoints()

    const native = useNativeCurrency()

    const showNative: boolean = useMemo(() => {
        if (tokensToShow || mode === 'onramp-input') return false
        const s = debouncedQuery.toLowerCase().trim()
        return native && native.symbol?.toLowerCase?.()?.indexOf(s) !== -1
    }, [debouncedQuery, native, tokensToShow, mode])

    const filteredTokens: Token[] = useMemo(() => {
        const filterToken = createFilterToken(debouncedQuery, (address) => Boolean(isAddress(address)))
        return Object.values(tokensToShow || allTokens).filter(filterToken)
    }, [tokensToShow, allTokens, debouncedQuery])

    const queryTokens = useSortedTokensByQuery(filteredTokens, debouncedQuery)
    const filteredQueryTokens = useMemo(() => {
        return queryTokens
    }, [queryTokens])

    const tokenComparator = useTokenComparator(invertSearchOrder)

    const filteredSortedTokens: Token[] = useMemo(() => {
        return onRampFlow ? [...filteredQueryTokens] : [...filteredQueryTokens].sort(tokenComparator)
    }, [filteredQueryTokens, tokenComparator, onRampFlow])

    const handleCurrencySelect = useCallback(
        (currency: Currency) => {
            onCurrencySelect(currency)
        },
        [onCurrencySelect],
    )

    // manage focus on modal show
    const inputRef = useRef<HTMLInputElement>()

    useEffect(() => {
        if (!isMobile) inputRef.current?.focus()
    }, [isMobile])

    const handleInput = useCallback((event) => {
        const input = event.target.value
        const checksummedInput = isAddress(input)
        setSearchQuery(checksummedInput || input)
        fixedList.current?.scrollTo(0)
    }, [])

    const handleEnter = useCallback(
        (e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                const s = debouncedQuery.toLowerCase().trim()
                if (s === native.symbol.toLowerCase().trim()) {
                    handleCurrencySelect(native)
                } else if (filteredSortedTokens.length > 0) {
                    if (
                        filteredSortedTokens[0].symbol?.toLowerCase() === debouncedQuery.trim().toLowerCase() ||
                        filteredSortedTokens.length === 1
                    ) {
                        handleCurrencySelect(filteredSortedTokens[0])
                    }
                }
            }
        },
        [debouncedQuery, filteredSortedTokens, handleCurrencySelect, native],
    )

    // if no results on main list, show option to expand into inactive
    const filteredInactiveTokens = useSearchInactiveTokenLists(debouncedQuery)

    const hasFilteredInactiveTokens = Boolean(filteredInactiveTokens?.length)

    const getCurrencyListRows = useCallback(() => {
        if (searchToken && !searchTokenIsAdded && !hasFilteredInactiveTokens) {
            return (
                <Column style={{ padding: '20px 0', height: '100%' }}>
                    <ImportRow
                        onCurrencySelect={handleCurrencySelect}
                        token={searchToken}
                        showImportView={showImportView}
                        setImportToken={setImportToken}
                    />
                </Column>
            )
        }

        return Boolean(filteredSortedTokens?.length) || hasFilteredInactiveTokens ? (
            <Box mx="-24px" my="24px">
                <CurrencyList
                    height={isMobile ? (showCommonBases ? height || 250 : height ? height + 80 : 350) : 328}
                    showNative={showNative}
                    currencies={filteredSortedTokens}
                    inactiveCurrencies={mode === 'onramp-input' ? [] : filteredInactiveTokens}
                    breakIndex={
                        Boolean(filteredInactiveTokens?.length) && filteredSortedTokens
                            ? filteredSortedTokens.length
                            : undefined
                    }
                    onCurrencySelect={handleCurrencySelect}
                    otherCurrency={otherSelectedCurrency}
                    selectedCurrency={selectedCurrency}
                    fixedListRef={fixedList}
                    showImportView={showImportView}
                    setImportToken={setImportToken}
                    mode={mode}
                />
            </Box>
        ) : (
            <Column style={{ padding: '20px', height: '100%' }}>
                <Text color="textSubtle" textAlign="center" mb="20px">
                    {t('No results found.')}
                </Text>
            </Column>
        )
    }, [
        filteredInactiveTokens,
        filteredSortedTokens,
        handleCurrencySelect,
        hasFilteredInactiveTokens,
        otherSelectedCurrency,
        searchToken,
        searchTokenIsAdded,
        selectedCurrency,
        setImportToken,
        showNative,
        showImportView,
        t,
        showCommonBases,
        isMobile,
        height,
        mode,
    ])

    return (
        <>
            <AutoColumn gap="16px">
                {showSearchInput && (
                    <Row>
                        <Input
                            id="token-search-input"
                            placeholder={t(onRampFlow ? 'Search name' : 'Search name or paste address')}
                            scale="lg"
                            autoComplete="off"
                            value={searchQuery}
                            ref={inputRef as RefObject<HTMLInputElement>}
                            onChange={handleInput}
                            onKeyDown={handleEnter}
                        />
                    </Row>
                )}
                {showCommonBases && (
                    <CommonBases
                        chainId={chainId}
                        onSelect={handleCurrencySelect}
                        selectedCurrency={selectedCurrency}
                        commonBasesType={commonBasesType}
                    />
                )}
            </AutoColumn>
            {getCurrencyListRows()}
        </>
    )
}

export default CurrencySearch
