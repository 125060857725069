import { useState } from 'react'
import { Token, Currency } from '@echodex/sdk'
import {
    Button,
    Text,
    ErrorIcon,
    Flex,
    Message,
    Checkbox,
    Link,
    Tag,
    Grid,
    BscScanIcon,
    ListLogo,
    useTooltip,
    HelpIcon,
    AutoColumn,
} from '@echodex/uikit'
import { useAddUserToken } from 'state/user/hooks'
import { getBlockExploreLink, getBlockExploreName } from 'utils'
import useSWRImmutable from 'swr/immutable'
import truncateHash from '@echodex/utils/truncateHash'
import { useCombinedInactiveList } from 'state/lists/hooks'
import { useTranslation } from '@echodex/localization'
import { WrappedTokenInfo } from '@echodex/token-lists'
import { chains } from 'utils/wagmi'
import { fetchRiskToken, TOKEN_RISK } from 'views/Swap/hooks/fetchTokenRisk'
import AccessRisk from 'views/Swap/components/AccessRisk'
import { useActiveChainId } from 'hooks/useActiveChainId'

interface ImportProps {
    tokens: Token[]
    handleCurrencySelect?: (currency: Currency) => void
}

const getStandard = () => 'ERC20'

function ImportToken({ tokens, handleCurrencySelect }: ImportProps) {
    const { chainId } = useActiveChainId()

    const { t } = useTranslation()

    const [confirmed, setConfirmed] = useState(false)

    const addToken = useAddUserToken()

    // use for showing import source on inactive tokens
    const inactiveTokenList = useCombinedInactiveList()

    const { data: hasRiskToken } = useSWRImmutable(tokens && ['has-risks', tokens], async () => {
        const result = await Promise.all(tokens.map((token) => fetchRiskToken(token.address, token.chainId)))
        return result.some((r) => r.riskLevel > TOKEN_RISK.MEDIUM)
    })

    const { targetRef, tooltip, tooltipVisible } = useTooltip(
        t('I have read the scanning result, understood the risk and want to proceed with token importing.'),
    )

    return (
        <AutoColumn gap="lg">
            <Message variant="warning">
                <Text>
                    {t(
                        'Anyone can create a token with any name, including creating fake versions of existing tokens or ones that claim to represent projects without tokens.',
                        {
                            standard: getStandard(),
                            network: chains.find((c) => c.id === chainId)?.name,
                        },
                    )}
                    <br />
                    <br />
                    <b>{t('If you purchase an arbitrary token, you may be unable to sell it back.')}</b>
                    <br />
                    <br />
                    <b>
                        {t(
                            'Please be extra careful during this period as altcoin trading volumes are on the surge recently.',
                        )}
                    </b>
                </Text>
            </Message>

            {tokens.map((token) => {
                const list = token.chainId && inactiveTokenList?.[token.chainId]?.[token.address]?.list
                const address = token.address ? `${truncateHash(token.address)}` : null
                return (
                    <Flex key={token.address} alignItems="center" justifyContent="space-between">
                        <Grid gridTemplateRows="1fr 1fr 1fr 1fr" gridGap="4px">
                            {list !== undefined ? (
                                <Tag
                                    variant="success"
                                    outline
                                    scale="sm"
                                    startIcon={list.logoURI && <ListLogo logoURI={list.logoURI} size="12px" />}
                                >
                                    {t('via')} {list.name}
                                </Tag>
                            ) : (
                                <Tag variant="failure" outline scale="sm" startIcon={<ErrorIcon color="failure" />}>
                                    {t('Unknown Source')}
                                </Tag>
                            )}
                            <Flex alignItems="center">
                                <Text mr="8px">{token.name}</Text>
                                <Text>({token.symbol})</Text>
                            </Flex>
                            {!!token.chainId && (
                                <>
                                    <Text mr="4px">{address}</Text>
                                    <Link href={getBlockExploreLink(token.address, 'address', token.chainId)} external>
                                        (
                                        {t('View on %site%', {
                                            site: getBlockExploreName(token.chainId),
                                        })}
                                        {token.chainId === chainId && <BscScanIcon color="primary" ml="4px" />})
                                    </Link>
                                </>
                            )}
                        </Grid>
                        <AccessRisk token={token} />
                    </Flex>
                )
            })}

            <Grid gridTemplateRows="1fr 1fr" gridGap="4px">
                <Flex alignItems="center" onClick={() => setConfirmed(!confirmed)}>
                    <Checkbox
                        scale="sm"
                        name="confirmed"
                        type="checkbox"
                        checked={confirmed}
                        onChange={() => setConfirmed(!confirmed)}
                    />
                    <Text ml="8px" style={{ userSelect: 'none' }}>
                        {hasRiskToken ? t('I acknowledge the risk') : t('I understand')}
                    </Text>
                    {hasRiskToken && (
                        <div ref={targetRef}>
                            <HelpIcon color="textSubtle" />
                            {tooltipVisible && tooltip}
                        </div>
                    )}
                </Flex>
                <Button
                    variant="danger"
                    disabled={!confirmed}
                    onClick={() => {
                        tokens.forEach((token) => {
                            const inactiveToken = chainId && inactiveTokenList?.[token.chainId]?.[token.address]
                            let tokenToAdd = token
                            if (inactiveToken) {
                                tokenToAdd = new WrappedTokenInfo({
                                    ...token,
                                    logoURI: inactiveToken.token.logoURI,
                                    name: token.name || inactiveToken.token.name,
                                })
                            }
                            addToken(tokenToAdd)
                        })
                        if (handleCurrencySelect) {
                            handleCurrencySelect(tokens[0])
                        }
                    }}
                    className=".token-dismiss-button"
                >
                    {hasRiskToken ? t('Proceed') : t('Import')}
                </Button>
            </Grid>
        </AutoColumn>
    )
}

export default ImportToken
